$(document).ready(function () {


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teleport links
    // *
    // * @set outer parent element class: js-href-teleport-wrapper
    // * @set link (<a> tag) element class: js-href-teleport-link
    // * @set element to add the link to class: js-href-teleport
    // *
    // * This adds a link tag (<a>) to other elements within a wrapper
    // * links comes from a link. Example: add a link to h2, image etc. inside a teaser
    // *
    $(".js-href-teleport").each(function () {
        var $link = $(this).parents(".js-href-teleport-wrapper").find(".js-href-teleport-link"),
            href = $link.attr("href"),
            target = $link.attr("target") ? $link.attr("target") : '_self';

        if (href) {
            $(this).wrapInner('<a href="' + href + '" target="' + target + '"></a>');
        }
    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parent clickable elements (excludes links inside)
    // *
    // * @set outer parent element class: js-click-item-parent
    // * @set link (<a> tag) element class: js-click-item-link
    // *
    // * This makes the whole element clickable and still
    // * makes other links inside clickable with their target
    // *
    $(".js-click-item-parent").delegate('a', 'click', function (e) {
        var target = $(this).attr("target"),
            url = $(this).attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    }).click(function () {
        var target = $(this).find("a.js-click-item-link").attr("target"),
            url = $(this).find("a.js-click-item-link").attr("href");

        if (target == "_blank") {
            window.open(url);
        } else {
            window.location = url;
        }
        return false;
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * animateIn
    // *
    // *
    var offset = 80; // Distance from Browserbottom & -top where the animation should start

    function fadeInElements() {
        var viewPortStart = $(window).scrollTop(),
            viewPortEnd = viewPortStart + $(window).height();


        $(".animateIn:visible").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {
                var delay = $(this).data("animation-delay");
                $(this).css("transition-delay", delay + "s").addClass("animateIn--active");
            } else {
                $(this).removeClass("animateIn--active");
            }
        });

        // * * * * * * * * * * * * * * * * * * * * * * * * *
        // * countup
        // *
        // *
        $(".js-countup").each(function () {
            var elementTop = $(this).offset().top,
                elementBottom = $(this).offset().top + $(this).outerHeight();

            if ((elementTop) <= viewPortEnd && (elementBottom) >= viewPortStart) {

                // check for countup - if in viepoint fire countUp (once)
                if (!$(this).hasClass("counted")) {

                    var options = {
                        useEasing: true,
                        useGrouping: true,
                        separator: '',
                        decimal: '.',
                    };

                    number = $(this).find(".js-countup-number");

                    number.each(function () {
                        // avoid jumping
                        var width = $(this).width();
                        $(this).css("width", width);

                        countTo = $(this).data("count");
                        var count = new CountUp($(this)[0], 0, countTo, 0, 4, options);
                        count.start();
                    });

                    $(this).addClass("counted");
                }
            }
        });

        if (window.innerWidth < 768) {
            $(".js-timeline-item").each(function () {
                var elementTop = $(this).offset().top,
                    elementBottom = $(this).offset().top + $(this).outerHeight();

                if ((elementTop + offset) <= viewPortEnd && (elementBottom - offset) >= viewPortStart) {

                    $(this).addClass("active");
                }
            });
        }
    }

    $(window).scroll(function () {
        fadeInElements();
    });

    fadeInElements();


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * add target blank to external links
    // *
    // *
    $('a:not([data-targetblank=ignore])').each(function () {
        if (location.hostname === this.hostname || !this.hostname.length) {
            // ... do nothing?
        } else {
            $(this).attr('target', '_blank');
        }
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-navbutton").click(function () {
        $(this).toggleClass("active");
        $(".js-nav").toggleClass("active");

        if ($(this).hasClass("active")) {
            setTimeout(() => {
                $("body").addClass("freeze");
            }, 500);
        } else {
            $("body").removeClass("freeze");

        }
    });

    $(".js-headerjump-button").click(function () {
        $(".js-headerjump-list").slideToggle();
        $(this).toggleClass("active");
    })

    if (window.innerWidth < 1000) {
        $(".js-headerjump-list ul li a").click(function () {
            $(".js-headerjump-list").slideUp();
        })
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * nav
    // *
    // *
    $(".js-has-submenu").click(function () {
        let isOpen = $(this).hasClass("open");
        console.log(isOpen)
        if (isOpen) {
            $(this).find(".js-submenu").slideUp();
            $(this).removeClass("open");
        }
        else {
            $(this).find(".js-submenu").slideDown();
            $(this).addClass("open");
        }
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * tileinfo
    // *
    // *
    if (window.matchMedia("(pointer: coarse)").matches) {

        $(".js-tileinfo-item").on('click', function () {
            $(this).toggleClass("active").find(".js-tileinfo-content").slideToggle();

            $(".js-tileinfo-item.active").not(this).each(function () {
                $(this).removeClass("active").find(".js-tileinfo-content").slideUp();
            });
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * teamSlider
    // *
    // *
    if ($(".js-swiper-team .swiper-slide").length > 0) {
        const teamSlider = new Swiper('.js-swiper-team', {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 10,
            autoHeight: 1,


            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        })
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * instagram slider
    // *
    // *
    if ($(".js-swiper-instagram .swiper-slide").length > 0) {


        const instagramSlider = new Swiper('.js-swiper-instagram', {
            loop: true,
            slidesPerView: 2.8,
            spaceBetween: 55,


            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            // Responsive breakpoints
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 1.3,
                    spaceBetween: 20
                },
                // when window width is >= 480px
                1000: {
                    slidesPerView: 1.8,
                    spaceBetween: 40
                },
                // when window width is >= 640px
                1440: {
                    slidesPerView: 2.8,
                    spaceBetween: 55
                }
            }
        })
    }

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * tooltip
    // *
    // *
    $(".js-tooltip-item").each(function () {
        let left = $(this)[0].style.left.slice(0, -1)
        if (left > 50) {
            $(this).addClass("tooltip__item--toLeft");
        }
    });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * tooltip
    // *
    // *
    if (window.innerWidth <= 1000) {
        var tooltipItems = $('.js-tooltip-content');
        tooltipItems.appendTo($('.js-tooltip-target'));

        $(".js-tooltip-item").click(function () {
            var index = $(this).index();
            if ($(this).hasClass("active")) {
                $(".js-tooltip-item").removeClass("active");
                $(".js-tooltip-target .js-tooltip-content").hide();
                $(".js-tooltip-target").removeClass("active");
            }
            else {
                $(".js-tooltip-target .js-tooltip-content").hide();
                $(".js-tooltip-target .js-tooltip-content").eq(index).show();
                $(".js-tooltip-target").addClass("active");
                $(this).siblings().removeClass("active");
                $(this).addClass("active");
            }
        })

        $(".js-close-tooltip").click(function () {
            $(".js-tooltip-item").removeClass("active");
            $(".js-tooltip-target .js-tooltip-content").hide();
            $(".js-tooltip-target").removeClass("active");
        });
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * tabs
    // *
    // *
    $(".js-tabs-nav-item").click(function () {
        let index = $(this).index();
        // nav
        $(this).siblings().removeClass("active");
        $(this).addClass("active");
        // content
        $(this).parents(".js-tabs-nav").siblings(".js-tabs-content").find(".js-tabs-content-item").removeClass("active scrolled");

        var $activeElement =  $(this).parents(".js-tabs-nav").siblings(".js-tabs-content").find(".js-tabs-content-item").eq(index);
        $activeElement.addClass("active");


        var $contentInner = $activeElement.find('.js-tabs-content-inner');

        if ($contentInner[0].scrollHeight > $contentInner.innerHeight() + 10 ) {
                $activeElement.addClass('overflow');
        }
    });

    $('.js-tabs-content-inner').on('scroll', function () {

        var $parent = $(this).parents(".js-tabs-content-item")
        if ($(this).scrollTop() > 0 ) {
            $parent.addClass('scrolled');
        }
    });

    $(".js-tabs-nav-item:nth-child(2)").click();

    // if (window.innerWidth < 1000) {


    //     $('.js-tabs-text').readmore({
    //         speed: 75,
    //         lessLink: '<a class="buttonText" href="#">Einklappen</a>',
    //         moreLink: '<a class="buttonText" href="#">Mehr lesen</a>',
    //         collapsedHeight: 400
    //     });
    // }

    // $('.js-tabs-content-item').each(function () {
    //     var $contentInner = $(this).find('.tabs__contentInner');
    //     var $tabsContent = $(this).closest('.js-tabs-content');

    //     if ($contentInner[0].scrollHeight > $contentInner.innerHeight() || 
    //         $contentInner[0].scrollWidth > $contentInner.innerWidth()) {
    //         $tabsContent.addClass('overflow');
    //     }
    // });

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * accordion
    // *
    // *
    $(".js-accordion-header").click(function () {
        $(this).parents(".js-accordion-item").toggleClass("active");
        $(this).parents(".js-accordion-item").siblings().removeClass("active").find(".js-accordion-content").slideUp();
        $(this).siblings(".js-accordion-content").slideToggle();
        if (!$(this).hasClass("no-scroll")) {
            setTimeout(() => {
                $(this).get(0).scrollIntoView({ behavior: 'smooth' })
            }, 400);
        }

    });

    // let hash = window.location.hash.split('#')[1];
    // $("#" + hash).find(".js-accordion-header").click();






    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * gsap smooth scrolling and anchor links
    // *
    // *
    if ($(".js-smooth-content").length) {

        ScrollTrigger.normalizeScroll(true);
        ScrollSmoother.create({
            smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
            smooth: 1,
            content: ".js-smooth-content"
        });
        let headerHeight = 90;

        // scroll to hash (from other site)
        let hash = window.location.hash.split('#')[1];
        setTimeout(() => {
            if (hash) {
                gsap.to(window, {
                    duration: 1,
                    scrollTo: { y: `#${hash}`, offsetY: headerHeight }
                });
            }
        }, 100);


        // scroll to hash (same site)
        $("a[href]").click(function (event) {
            // may have to change path structure
            let path = "/" + $(this).attr("href").split("#")[0];
            let hash = $(this).attr("href").split("#")[1];

            if (path === window.location.pathname) {
                event.preventDefault();

                gsap.to(window, {
                    duration: 1,
                    scrollTo: { y: `#${hash}`, offsetY: headerHeight }
                });
            }
        })
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * timeLine
    // *
    // *

    if (window.innerWidth > 768) {
        var $timeline = $(".js-timeline");

        $timeline.each(function () {
            var $item = $(this).find(".js-timeline-item"),
                $wrapper = $(this).parents(".js-timeline-wrapper"),
                itemCount = $item.length,
                headerHeight = $(".js-header").height() + $(".js-header-current").height() + 30,
                startOffset = -0,
                animationPriority = 1000 - $(this).parents(".js-gsap-wrapper").data("index");

            const timeLineWidth = $(this)[0].scrollWidth;
            const scrollWidth = timeLineWidth - window.innerWidth + window.innerWidth * .25;

            gsap.to($item, {
                x: -scrollWidth,
                ease: "none",
                scrollTrigger: {
                    trigger: $wrapper,
                    scrub: true,
                    pin: true,
                    snap: 1 / (itemCount - 1),
                    markers: false,
                    start: "top+=" + startOffset + "% top+=" + headerHeight,
                    end: "bottom+=1000% top+=" + headerHeight,
                    refreshPriority: animationPriority,
                    // Update the "number" element with the current progress
                    onUpdate: function (self) {
                        const scaledProgress = self.progress * itemCount;
                        const index = Math.floor(scaledProgress);

                        if (index + 1 > itemCount) {
                            return;
                        } else {

                            let currentItem = $timeline.find(".js-timeline-item").eq(index);
                            currentItem.addClass("active");
                        }
                    }
                }
            });
        });
    }





});



// * * * * * * * * * * * * * * * * * * * * * * * * *
// * sticky Header
// *
// *
$(window).on("load scroll", function () {
    if ($(window).scrollTop() >= 40) {
        $(".js-header").addClass("sticky");
    } else {
        $(".js-header").removeClass("sticky");
    }

    var element = $('.js-headerjump-flag').next();
    // use this element for singe modules. Otherwise use the line above
    // var element = $('.js-hero-headerjump').first();

    var scrollPosition = $(window).scrollTop(); // Get current scroll position
    var elementBottom = element.offset().top + element.outerHeight(); // Get bottom position of the element
    var headerHeight = 100;

    // Check if the scroll position plus the window height is greater than the element's bottom
    if (scrollPosition + headerHeight >= elementBottom) {
       $(".js-header-current").slideDown();
    } else {
        $(".js-header-current").slideUp();
    }
});

// * * * * * * * * * * * * * * * * * * * * * * * * *
// *  clone
// *
// *
$(window).on("load resize", function () {

    if (window.innerWidth <= 1000) {
        // $(".js-intro").find(".js-intro-clone-content").each(function () {
        //     $(".js-intro-target").append($(this))
        // })

        let src = $(".js-intro").find(".js-breadcrumb-src");
        $(".js-breadcrumb-target").append(src);
    }
})